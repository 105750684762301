import React, { useState, useEffect, useRef } from 'react';
import { Typography, Grid, Button, Box, MenuItem, Select, TextField, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';

import mammoth from 'mammoth';
import pako from 'pako';
import useApi from '../useApi';
import "./DocxViewer.css";
import LoaderComponent from '../Common/LoaderComponent';
import { LoadingState } from '../Common/LoaderComponent';
import theme from '../theme';
import AddTemplateDialog from './AddTemplateDialog';
import EditTemplateDialog from './EditTemplateDialog';
import VersatileAlertDialog from '../UI/VersatileAlertDialog';
import Alert from '@mui/material/Alert';

const getRandomColor = (existingColors) => {
  let color;
  do {
    const red = Math.floor(Math.random() * 156) + 100;
    const green = Math.floor(Math.random() * 156) + 100;
    const blue = Math.floor(Math.random() * 156) + 100;
    color = `rgb(${red}, ${green}, ${blue})`;
  } while (existingColors.includes(color));
  return color;
};

const DocxViewer = ({ contract, onClose }) => {
  const [showInitialButton, setShowInitialButton] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loadingStateType, setLoadingStateType] = useState(LoadingState.SCANNING);
  const [docxFile, setDocxFile] = useState(null);
  const [docxContent, setDocxContent] = useState('');
  const [selections, setSelections] = useState({});
  const [originalSelections, setOriginalSelections] = useState({});
  const [selectedText, setSelectedText] = useState(null);
  const [currentTemplate, setCurrentTemplate] = useState('');
  const [customTemplate, setCustomTemplate] = useState('');
  const [existingColors, setExistingColors] = useState([]);
  const customSelectionValue = 'מותאם אישית';
  const [templateOptions, setTemplateOptions] = useState([customSelectionValue]);

  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [editText, setEditText] = useState('');
  const [editTemplate, setEditTemplate] = useState('');
  const contentRef = useRef(null);

  const [contractNameDialogOpen, setContractNameDialogOpen] = useState(false);
  const [contractName, setContractName] = useState('');
  const [versatileAlertOpen, setVersatileAlertOpen] = useState(false);
  const [alertVisible, setAlertVisible] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const { useScanAndExtractDoc, useSubmitContract, useOriginalContractLink } = useApi();
  const scanAndExtractDoc = useScanAndExtractDoc();
  const submitContract = useSubmitContract();
  const originalContractLink = useOriginalContractLink();

  useEffect(() => {
    const content = contentRef.current;
    if (content) {
      const handleDelegatedClick = (event) => {
        let target = event.target;
        while (target && !target.matches('button[data-template-text]')) {
          target = target.parentElement;
        }
        if (target) {
          handleHighlightedTextClick({ target });
        }
      };

      content.addEventListener('click', handleDelegatedClick);

      return () => {
        content.removeEventListener('click', handleDelegatedClick);
      };
    }
  }, [docxContent]);

  useEffect(() => {
    const content = contentRef.current;
    if (content) {
      content.addEventListener('mouseup', handleMouseUp);
    }
    return () => {
      if (content) {
        content.removeEventListener('mouseup', handleMouseUp);
      }
    };
  }, [contentRef]);

  useEffect(() => {
    if (!contract || !contract.file_url) {
        return;
    }
    const handlePossibleEditMode = async () => {
        setLoading(true);
        try {
            const file = await downloadContract(contract.file_url);
            await performDocxToString(file);
            await handleNewDocSelections(contract.payload);
            setShowInitialButton(false);
        } catch (error) {
            console.error('handlePossibleEditMode:', error);
            setVersatileAlertOpen(true)
            resetState()
        } finally {
            setLoading(false);
        }
    };
    handlePossibleEditMode();
  }, []);

  const downloadContract = async (url) => {
    try {
        const valid_url = await originalContractLink(url);
        const response = await fetch(valid_url[0]);
        if (!response.ok) {
            throw new Error('Failed to download file');
        }
        const blob = await response.blob();
        return blob;
    } catch (error) {
        console.error('Error downloading the file:', error);
        throw error
    }
  }

  const handleFileUpload = async (event) => {
    try {
        const file = event.target.files[0];
        startFileUpload(file);
    } catch (error) {
        console.error('Error downloading the file:', error);
        setVersatileAlertOpen(true)
        resetState()
    } finally {
      event.target.value = null;
    }
  };

  const startFileUpload = async (file) => {
    setShowInitialButton(false);
    setLoadingStateType(LoadingState.SCANNING);
    setLoading(true);

    if (file) {
      const arrayBuffer = await performDocxToString(file);
      handleSendToBackend(arrayBuffer);
    } else {
      setAlertMessage("אנחנו מתקשים למצוא את הקובץ הנבחר. כרגע אנחנו תומכים רק בקבצי docx 😀")
      setAlertVisible(true)
      resetState()
    }
  };

  const performDocxToString = async (file) => {
    setDocxFile(file);
    const arrayBuffer = await file.arrayBuffer();
    const result = await mammoth.convertToHtml({ arrayBuffer });
    setDocxContent(result.value);
    return arrayBuffer
  };

  const handleSendToBackend = async (arrayBuffer) => {
    if (!arrayBuffer) {
      console.error("No file selected");
      return;
    }
    const result = await mammoth.extractRawText({ arrayBuffer });
    let rawText = result.value;
    rawText = rawText.replace(/\s+/g, ' ').trim();

    const compressed = pako.gzip(rawText, { to: 'string' });
    const blob = new Blob([compressed], { type: 'application/gzip' });

    const formData = new FormData();
    formData.append('file', blob, 'content.zip');
    try {
      const response = await scanAndExtractDoc(formData);
      handleNewDocSelections(response);
    } catch (error) {
      console.error("Error scanAndExtractDoc: ", error);
      throw error
    } finally {
        setLoading(false);
    }
  };

  const handleNewDocSelections = async(response) => {
    console.log("Success scanAndExtractDoc: ", response);
      const newSelections = processData(response);

      if (newSelections) {
        const updatedSelections = { ...selections };

        for (const [key, value] of Object.entries(newSelections)) {
          const color = getRandomColor(existingColors);
          const template = value;

          // Ensure the template is in the templateOptions array
          if (!templateOptions.includes(template)) {
            setTemplateOptions((prevOptions) => [...prevOptions, template]);
          }

          setExistingColors((prevColors) => [...prevColors, color]);
          updatedSelections[key] = { template, color };
        }

        setSelections(updatedSelections);
        // if in edit mode
        if (contract) {
            setOriginalSelections(newSelections);
        }
      } else {
        console.error("No data received from the backend");
      }
  };

  const processData = (data) => {
    let parsedData;
    if (typeof data === 'string') {
        try {
            parsedData = JSON.parse(data);
        } catch (error) {
            console.error("Failed to parse JSON:", error);
            setAlertMessage("לא הצלחנו למצוא מילות מפתח, נסו שוב או סמנו ידנית ? בכל מקרה אנחנו על זה 🙂")
            setAlertVisible(true)
            parsedData = {};
        }
    } else if (typeof data === 'object' && data !== null) {
        parsedData = data;
    } else {
        console.warn("Unexpected data type:", typeof data);
        parsedData = {};
    }
    return parsedData;
}

  const handleContractSubmission = async (contractName) => {
    if (docxFile) {
      setLoadingStateType(LoadingState.UPLOADING);
      setLoading(true);
      try {
        const file = docxFile;
        const formData = new FormData();
        formData.append("file", file);

        if (contract && contract.id) {
            formData.append("id", contract.id);
            formData.append("original_selections", JSON.stringify(originalSelections));
        }
        const simplifiedSelections = {};
        Object.keys(selections).forEach((key) => {
          simplifiedSelections[key] = selections[key].template;
        });
        const selectionsJson = JSON.stringify(simplifiedSelections);
        formData.append("selections", selectionsJson);

        const contractMetadata = {
            name: contractName ? contractName : file.name, 
            date: new Date().toISOString(),
          };
          const metadataJson = JSON.stringify(contractMetadata);
          formData.append('metadata', metadataJson);

        const response = await submitContract(formData);
        console.log(response)
        onClose(true);
      } catch (error) {
        console.error("error handleContractSubmission ", error);
        setVersatileAlertOpen(true)
      } finally {
        setLoading(false);
      }
    }
  };

  const handleMouseUp = () => {
    const selection = window.getSelection();
    if (selection && selection.toString().length > 0) {
      setSelectedText(selection.toString());
      setCurrentTemplate(customSelectionValue);
    }
  };

  const handleSaveSelection = () => {
    if (selectedText && (currentTemplate || customTemplate)) {
      const template = customTemplate || currentTemplate;
      const color = getRandomColor(existingColors);
      setExistingColors([...existingColors, color]);
      setSelections({
        ...selections,
        [selectedText]: { template, color },
      });
      if (customTemplate && !templateOptions.includes(customTemplate)) {
        setTemplateOptions([...templateOptions, customTemplate]);
      }
      setSelectedText(null);
      setCurrentTemplate('');
      setCustomTemplate('');
    }
  };

  const handleCancelSelection = () => {
    setSelectedText(null);
    setCurrentTemplate('');
    setCustomTemplate('');
  };

  const handleRemoveSelection = (text) => {
    const newSelections = { ...selections };
    delete newSelections[text];
    setSelections(newSelections);
  };

  const handleEditSelection = (text) => {
    setEditText(text);
    setEditTemplate(selections[text].template);
    setEditDialogOpen(true);
  };

  const handleEditDialogClose = () => {
    setEditDialogOpen(false);
    setEditText('');
    setEditTemplate('');
  };

  const handleEditSave = () => {
    const updatedSelections = { ...selections };
    const oldTemplate = updatedSelections[editText].template;
    updatedSelections[editText].template = editTemplate;
  
    // Update templateOptions by removing the old value and adding the new value
    setTemplateOptions((prevOptions) => {
      const newOptions = prevOptions.filter((option) => option !== oldTemplate);
      if (!newOptions.includes(editTemplate)) {
        newOptions.push(editTemplate);
      }
      return newOptions;
    });
  
    setSelections(updatedSelections);
    handleEditDialogClose();
  };
  

  const handleChangeTemplate = (text, newTemplate, customTemplateValue = '') => {
    setSelections({
      ...selections,
      [text]: { ...selections[text], template: newTemplate, customTemplate: customTemplateValue },
    });
  };

  const handleHighlightedTextClick = (event) => {
    const encodedText = event.target.getAttribute('data-template-text');
    const text = decodeURIComponent(encodedText);
    const element = document.getElementById(`template-${encodedText}`);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      addTemporaryHighlight(element);
    }
  };

  const handleRightSideClick = (text) => {
    const encodedText = encodeURIComponent(text);
    const buttonElement = document.getElementById(`highlight-${encodedText}`);
    if (buttonElement) {
      buttonElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      addTemporaryHighlight(buttonElement);
    }
  };

  const addTemporaryHighlight = (element) => {
    element.classList.add('highlight-temp', 'fade-in'); // Apply both classes
    setTimeout(() => {
      element.classList.remove('highlight-temp', 'fade-in'); // Remove the classes after the animation
    }, 2000); // Match this duration to the total duration of the `highlightVisible` animation
  };

  const highlightText = () => {
    const sortedSelections = Object.entries(selections).sort((a, b) => b[0].length - a[0].length);
  
    let contentHtml = docxContent;
  
    sortedSelections.forEach(([text, selection]) => {
      const encodedText = encodeURIComponent(text);
      const escapedText = text.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
      const regex = new RegExp(`(?<!<button[^>]*>)(?<!</button>)(?<!\\w)${escapedText}(?!\\w)(?![^<]*</button>)`, 'g');
  
      const highlightedText = `<button 
          id="highlight-${encodedText}"
          style="background-color: ${selection.color}; cursor: pointer;"
          title="${selection.template}"
          data-template-text="${encodedText}"
          class="highlight-button"
      >${text}</button>`;
      contentHtml = contentHtml.replace(regex, highlightedText);
    });
  
    return { __html: contentHtml };
  };

  const handleOpenContractNameDialog = () => {
    if (contract && contract.name) {
        handleSaveContractName(contract.name);
    } else {
        setContractNameDialogOpen(true);
    };
  };

  const handleCloseContractNameDialog = () => {
    setContractNameDialogOpen(false);
  };

  const handleSaveContractName = (name) => {
    handleContractSubmission(name);
    setContractNameDialogOpen(false);
  };

  const handleVersatileAlertClose = () => {
    setVersatileAlertOpen(false);
  };

  const resetState = () => {
    setShowInitialButton(true);
    setLoading(false);
    setLoadingStateType(LoadingState.SCANNING);
    setDocxFile(null);
    setDocxContent('');
    setSelections({});
    setOriginalSelections({});
    setSelectedText(null);
    setCurrentTemplate('');
    setCustomTemplate('');
    setExistingColors([]);
    setTemplateOptions([customSelectionValue]);
    setEditDialogOpen(false);
    setEditText('');
    setEditTemplate('');
    setContractNameDialogOpen(false);
    setContractName('');
  };

  const handleWarningAlertClosed = () => {
    setAlertVisible(false)
  }

  return (
    <Box sx={{ flexGrow: 1, padding: 2 }}>
      <VersatileAlertDialog
        open={versatileAlertOpen}
        onClose={handleVersatileAlertClose}
        onTryAgain={handleVersatileAlertClose}
      />
      {alertVisible && (
        <Alert
          style={{
            position: "absolute",
            top: "15%",
            left: "32%",
          }}
          severity="warning"
          onClose={handleWarningAlertClosed}
        >
          {alertMessage}
        </Alert>
      )}
      <LoaderComponent loading={loading} loadingState={loadingStateType} />
      <Grid container spacing={2}>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          {showInitialButton ? (
            <>
              <input
                type="file"
                accept=".docx"
                onChange={handleFileUpload}
                style={{ display: "none" }}
                id="docx-upload"
              />
              <label htmlFor="docx-upload">
                <Button
                  variant="contained"
                  component="span"
                  endIcon={
                    <AutoAwesomeIcon
                      sx={{ color: theme.palette.secondary.main }}
                    />
                  }
                >
                  סרוק חוזה באמצעות AI
                </Button>
              </label>
            </>
          ) : (
            <>
              {contract && contract.name && (
                <Typography variant="subtitle1" gutterBottom>
                  <strong>שם החוזה:</strong> {contract.name}
                </Typography>
              )}
              <Button
                variant="contained"
                sx={{ backgroundColor: theme.palette.secondary.main }}
                onClick={handleOpenContractNameDialog}
              >
                יצא מדהים, שמור הכל
              </Button>
            </>
          )}
        </Grid>
        <Grid item xs={6}>
          <div
            ref={contentRef}
            dangerouslySetInnerHTML={highlightText()}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              height: "80vh",
              overflowY: "scroll",
              boxSizing: "border-box",
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <div
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              height: "80vh",
              overflowY: "scroll",
              boxSizing: "border-box",
            }}
          >
            {Object.keys(selections).map((text, index) => (
              <div
                key={index}
                id={`template-${encodeURIComponent(text)}`}
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "10px",
                  cursor: "pointer",
                }}
                onClick={() => handleRightSideClick(text)}
              >
                <div
                  style={{
                    backgroundColor: selections[text].color,
                    padding: "2px 5px",
                  }}
                >
                  {text}
                </div>
                <Select
                  value={selections[text].template}
                  onChange={(e) =>
                    handleChangeTemplate(
                      text,
                      e.target.value,
                      e.target.value === customSelectionValue
                        ? ""
                        : selections[text].customTemplate
                    )
                  }
                  displayEmpty
                  style={{ marginLeft: "10px" }}
                >
                  {templateOptions.map((option, idx) => (
                    <MenuItem key={idx} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
                {selections[text].template === customSelectionValue && (
                  <TextField
                    value={selections[text].customTemplate}
                    onChange={(e) =>
                      handleChangeTemplate(
                        text,
                        customSelectionValue,
                        e.target.value
                      )
                    }
                    placeholder="Enter custom template"
                    style={{ marginLeft: "10px" }}
                  />
                )}
                <IconButton
                  onClick={() => handleRemoveSelection(text)}
                  aria-label="מחק רשומה"
                  style={{ marginLeft: "10px" }}
                >
                  <CloseIcon />
                </IconButton>
                <IconButton
                  onClick={() => handleEditSelection(text)}
                  aria-label="ערוך מילת מפתח"
                  style={{ marginLeft: "10px" }}
                >
                  <EditIcon />
                </IconButton>
              </div>
            ))}
          </div>
        </Grid>
      </Grid>

      <EditTemplateDialog
        editDialogOpen={editDialogOpen}
        handleEditDialogClose={handleEditDialogClose}
        editTemplate={editTemplate}
        setEditTemplate={setEditTemplate}
        handleEditSave={handleEditSave}
      />

      <AddTemplateDialog
        selectedText={selectedText}
        handleCancelSelection={handleCancelSelection}
        handleSaveSelection={handleSaveSelection}
        currentTemplate={currentTemplate}
        setCurrentTemplate={setCurrentTemplate}
        templateOptions={templateOptions}
        customSelectionValue={customSelectionValue}
        customTemplate={customTemplate}
        setCustomTemplate={setCustomTemplate}
      />

      <Dialog
        open={contractNameDialogOpen}
        onClose={handleCloseContractNameDialog}
      >
        <DialogTitle>הזן שם חוזה</DialogTitle>
        <DialogContent>
          <DialogContentText>
            אנא הזן את שם החוזה שברצונך לשמור:
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="שם החוזה"
            type="text"
            fullWidth
            value={contractName}
            onChange={(e) => setContractName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseContractNameDialog} color="secondary">
            ביטול
          </Button>
          <Button
            onClick={() => handleSaveContractName(contractName)}
            color="primary"
          >
            שמור
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default DocxViewer;
